<template>
  <div class="full p_f">
    <nav class="order_nav d_f ali_c">
      <img src="../img/black_back.png" alt="" class="cur_p" @click="$emit('close')">
      <span>发表评价</span>
    </nav>
    <main class="c_content">
      <!--星级-->
      <div class="top_rate d_f ali_c">
        <div class="t_a">
          <div class="num">{{rate.toFixed(1)}}</div>
          <div class="text">综合评分</div>
        </div>
        <div class="flex_1">
          <van-rate
            v-model="rate"
            :size="25"
            allow-half
            color="#20C178"
            void-icon="star-o"
            void-color="#20C178"
          />
        </div>
      </div>
      <!--输入框-->
      <div class="p_r text_out">
        <textarea placeholder="分享一下您在酒店的体验吧~" maxlength="300" v-model="text"></textarea>
        <div class="p_a num_tip">{{text.length}}/300 最多可输入300字</div>
      </div>
      <!--图片展示-->
      <div class="img_out clear">
        <div class="p_r van_img_out f_l" v-for="(item,index) in fileList">
          <van-image
            class="p_r"
            fit="cover"
            :src="item.filePath" alt=""
          />
          <span class="p_a" @click="fileList.splice(index,1)">×</span>
        </div>
      </div>
      <!--上传-->
      <div class="upload_out d_f" v-if="fileList.length<9">
        <div class="input_out">
          <label for="ancamera" v-if="$global.modelState">
            <input multiple="multiple" id="ancamera" type="file" @change="handlerImage" ref="inputer" accept="image/*" />
            <img src="../img/up_img.png" alt="上传图片" />
            <div>上传图片</div>
          </label>
          <label for="ioscamera" v-else>
            <input multiple="multiple" id="ioscamera" type="file" @change="handlerImage" ref="inputer" accept="image/*" />
            <img src="../img/up_img.png" alt="上传图片" />
            <div>上传图片</div>
          </label>
        </div>
        <span class="num_tip">{{fileList.length}}/9最多上传9张</span>

      </div>
      <!--提交按钮-->
      <div class="btn t_a cur_p" @click="submitComment">提交评价</div>
    </main>
  </div>
</template>

<script>
  import apis from '../utils/apis'
  import { Toast } from 'vant';
  export default {
    name: "hotelComment",
    watch:{
      text(n,o){
        if(n.length>300){
          this.text = n.substring(0,300)
        }
      }
    },
    props:{
      orderId:{
        type: Number,
        default(){
          return 0
        }
      },
    },
    data() {
      return {
        rate:5,
        text:'',
        fileList:[],
      }
    },
    methods: {
      //提交评价
      submitComment(){
        let _t = this;
        if(!_t.text) return _t.$toast('请输入评价内容');
        if(!_t.fileList.length) return _t.$toast('请上传图片');
        let fileIds = [];
        _t.fileList.map((item,index) => {
          fileIds.push(item.fileId)
        });
        _t.$post({
          url: apis.insertUserCriticism,
          params:{
            token:_t.$global.token,
            data:{
              fileIdsStr:fileIds.join(','),
              starScore:_t.rate,
              bizType:12,
              bizId:_t.orderId,
              content:_t.text,
              parentId:0,
            }
          }
        }).then(rsp => {
          _t.$toast('评论成功');
          setTimeout(function () {
            _t.fileList = [];
            _t.rate = 5;
            _t.text = '';
            _t.$emit('save');
          },1500)
        })
      },
      uploadImg(dataURL){
        let me = this;
        let formData = new FormData();
        formData.append('file',dataURL);
        formData.append('bizType',1);
        me.$post({
          url: apis.upload,
          params:formData
        }).then(rsp => {
          me.fileList.push(rsp.data);
          Toast.clear();
        })
      },
      // 上传图片
      handlerImage(){
        let that = this;
        that.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: ''
        });
        // let file = this.$refs.inputer.files[0];
        let file = this.$refs.inputer.files;
        if(file.length > 9) return this.$toast('超出图片数量限制');
        let isPass = true;
        for(let i in file){
          if(!isNaN(i)){
            let photoExt=file[i].name.substring(file[i].name.lastIndexOf(".")).toLowerCase();//获得文件后缀名
            if(photoExt!='.bmp'
              && photoExt!='.jpg'
              && photoExt!='.tif'
              && photoExt!='.gif'
              && photoExt!='.pcx'
              && photoExt!='.png'
              && photoExt!='.tga'
              && photoExt!='.exif'
              && photoExt!='.fpx'
              && photoExt!='.svg'
              && photoExt!='.WMF'
              && photoExt!='.webp'){
              isPass = false;
            }
          }
        }
        if(!isPass) {
          return this.$toast('只能上传图片')
        };
        if(isPass){
          for(let i in file){
            if(!isNaN(i)){
              that.uploadImg(file[i])
            }
          }
        }
        /*file.map(item => {
          that.uploadImg(item)
        })*/
        /*let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          let content = this.result;
          if(content){
            let img = new Image();
            img.src = content;
            img.onload = function (){
              let q = file.size > 64*1024 ? 64*1024/file.size : 1;
              that.compressEvent(img, q,(dataURL)=> {
                that.uploadImg(dataURL);
              })
            }
          }
        };*/
      },
      // 压缩并转码
      compressEvent(img, q, callback){
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        // let width = img.width;
        // let height = img.height;
        let width = 960;
        let height = 1500;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        let dataURL = canvas.toDataURL("image/jpeg", q);
        callback ? callback(dataURL):null;
      },
    }
  }
</script>

<style scoped lang="scss">
  .img_out{
    margin-top: 14px;
    .van_img_out{
      width: calc((100% - 50px) / 5);
      height: 56px;
      margin: 0 10px 10px 0;
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      overflow: hidden;
      &:nth-child(5n){
        margin-right: 0;
      }
      span{
        right: 0;
        top: 0;
        background-color: #999999;
        color: white;
        width: 12px;
        height: 12px;
        text-align: center;
        line-height: 10px;
      }
    }
    .van-image{
      width: 100%;
      height: 100%;
    }
  }
  .upload_out{
    align-items: flex-end;
    .num_tip{
      font-size: 11px;
      font-weight: 500;
      color: #BFBFBF;
    }
    .input_out{
      width: 56px;
      height: 56px;
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      text-align: center;
      margin-right: 11px;
      padding-top: 4px;
      box-sizing: border-box;
      div{
        font-size: 11px;
        font-weight: 500;
        color: #BFBFBF;
      }
      img{
        width: 29px;
      }
      input{
        display: none;
      }
    }
  }
  .btn{
    line-height: 42px;
    height: 42px;
    background: #20C178;
    border-radius: 21px;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 62px;
  }
  .text_out{
    margin-bottom: 14px;
    padding: 13px 14px 27px 14px;
    box-sizing: border-box;
    .num_tip{
      font-size: 14px;
      font-weight: 500;
      color: #D1D1D1;
      bottom: 9px;
      right: 13px;
      background: white;
    }
    textarea{
      &::placeholder{
        font-size: 14px;
        font-weight: 500;
        color: #D1D1D1;
      }
      font-size: 14px;
      resize:none;
      color: #333333;
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
    margin-top: 27px;
    height: 188px;
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .top_rate{
    .van-rate__icon{
      font-size: 32px!important;
    }
    .t_a{
      color: #20C278;
      margin-left: 50px;
      margin-right: 14px;
      .num{
        font-size: 21px;
      }
      .text{
        font-size: 12px;
      }
    }
  }
  .c_content{
    padding: 33px 22px;
  }
  .order_nav{
    background: white;
    span{
      color: #3E3E3E!important;
    }
    border-bottom: 1px solid #DEDEDE;
  }
  .full{
    top: 0;
    left: 0;
    z-index: 100;
    background-color: white;
  }
</style>
